@import "../../../../styles/partials/palettes";
@import "../../../../styles/partials/typography";
@import "../../../../styles/partials/mixin";
@import "../../../../styles/partials/fonts";
@import "../../../../styles/partials/grid";

.SciWriting {
  &-wrapper {
    // .breadcrumbs {
    //   padding: 0px;
    //   ul li {
    //     padding-right: 5px;
    //     a {
    //       color: $secondrycolor;
    //     }
    //   }
    // }
    .breadcrumbs {
      padding: 5px 20px;
      ul {
          display: flex;
          li {
              padding-right: 5px;
              a {
                  color: $secondrycolor;
              }
          }
      }
  }
    .sci-Toolbar {
      font-family: $secondryfont;
      font-size: 28px;
      line-height: 36px;
      font-weight: 700;
      color: $primarycolor;
      margin-top: 0;
      margin-bottom: 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0px;
      .stroke-btn {
        @include stroke-btn;
      }
      .infoIcon {
        font-size: $body-size;
        font-weight: 500;
        color: $secondrycolor;
        font-family: $primaryfont;
        .info-icon {
          width: 20px;
          height: 20px;
          display: inline-block;
          line-height: 18px;
          border: 1px solid #155b98;
          border-radius: 50%;
          margin-right: 2px;
        }
      }
    }
    .planning__midd {
      padding: 0 20px;
      float: left;
      width: calc(100vw - 615px);
    }
    .planning__left {
      width: 280px;
      height: 100vh;
      padding-top: 16px;
      background: $strategic-filter-bg;
      max-height: inherit;
      &-searchbar {
        h2 {
          font-size: 18px;
          font-family: $secondryfont;
          font-weight: bold;
        }
      }
      &-links {
        li {
          padding: 6px 0px 6px 0px;
          position: relative;
          &.notReadableContent a {
            font-weight: 500;
          }
          &.readableContent {
            opacity: 0.8;
            strong {
              font-weight: normal;
            }
          }
          a {
            width: 100%;
            display: block;
            padding: 6px 20px;
            color: $black;
            font-size: $body-size;
          }
          .additionalInfo {
            padding: 0 20px;
          }
          &:hover,
          &:focus {
            background-color: $menuHover;
          }
        }
      }
      .meetings {
        padding: 8px 20px;
        border-bottom: 1px solid $border-color;
        color: $black;
      }
      .agendaCalender {
        padding: 8px 20px;
        margin-top: 50px;
        a {
          color: $secondrycolor;
          text-decoration: underline;
        }
        svg {
          display: inline-block;
          vertical-align: sub;
          margin-right: 8px;
        }
      }
      &-searchbar {
        position: relative;
        button.sciFilterBtn {
          position: absolute;
          right: 30px;
          top: 10px;
          svg {
            color: #5c7080;
          }
        }
        .bp3-icon-search {
          margin-top: 12px;
          margin-bottom: 12px;
        }
        input {
          padding-right: 30px;
          height: auto !important;
          padding-top: 5px;
          padding-bottom: 5px;
          border-radius: 5px;
        }
      }
    }
    .collapse-toggle {
      & > .planning__left {
        margin-left: -281px;
      }
      .planning__midd {
        width: calc(100vw - 335px);
      }
    }
  }
  &-topBar {
    padding: 0px 20px;
  }
}

/*----Left Bar css----*/
.sci-leftbar {
  height: 100vh;
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  border-right: 1px solid $border-color;
  background-color: $leftBar_bg;
  width: 70px;
  float: left;
  z-index: 2;
  li {
    position: relative;
    button {
      width: 40px;
      height: 40px;
      display: block;
      background-color: $leftBar_bg;
      border-radius: 50px;
      border: 2px solid $secondrycolor;
      margin: 16px auto;
      min-width: inherit;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        width: 20px;
        height: 25px;
        path {
          fill: $secondrycolor;
        }
      }
    }
    &.activeItem {
      button {
        background-color: $secondrycolor;
        outline: 2px solid $white-color;
        svg {
          path {
            fill: $white-color;
          }
        }
      }
    }
  }
}

.planning__left,
.planning__midd {
  transition: 500ms;
}
.collapse-toggle > .planning__left {
  margin-left: -240px;
}
.left-bar-toggle {
  z-index: 1;
}

/*-----SCI Middle Content SCSS------*/
.sciMid {
  &-topBar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 32px 0 16px 0;
    margin-bottom: 16px;
    button {
      @include stroke-btn;
    }
    .agenda {
      font-size: $medium-l-size;
      font-family: $secondryfont;
      font-weight: 400;
      color: $greyShade_3;
      text-transform: uppercase;
    }
    h2 {
      font-size: $large-size;
      font-family: $secondryfont;
      font-weight: bold;
      margin-top: 12px;
    }
  }
}

.publishedNotes {
  padding: 15px 0 0 15px;
  &-topbar{
      h2{
        font-size: 22px;
        font-family: "Palanquin";
        font-weight: bold;
      }
  }
  &-header{
      padding-right: 50px;
      position: relative;
      h4{
          font-size:$medium-l-size;
          font-weight: 600;
          margin-bottom: 8px;
      }
      p{
        color: $greyShade_3;
      }
  }
  &-action{
      @include position(absolute, $top: 0px, $right: 0px, $bottom: null, $left: null);
      button{
          color: $secondrycolor;
          & + button{
              margin-left:8px;
          }
      }
  }
  &-content{
      margin-top: 15px;
  }
  .createNote_editor {
    float: left;
    height: 100vh;
    width: 100%;
    .sciMid-topBar {
      padding: 5px 20px 5px 20px;
      border-bottom: 1px solid $border-color;
      position: relative;
      h2 {
        margin-top: 0px;
      }
      a {
        color: $secondrycolor;
        .bp3-icon {
          vertical-align: middle;
          margin-top: -3px;
        }
      }
    }
    .editorWrapper {
      padding: 0 18px 20px;
    }
    .filesWrapper {
      text-align: left;
      border: 1px dotted $border-color;
      // border-left: none;
      margin: 20px 0;
      .labelHeading {
        border-bottom: 1px solid $border-color;
        padding: 15px 20px;
      }
      &-buttonsDiv {
        text-align: center;
        padding: 15px 20px;
        button {
          @include stroke-btn;
          & + button {
            margin-left: 8px;
          }
        }
      }
      &-uploadedFiles {
        padding: 5px 20px;
        margin: 0 auto;
        .filesName_Chips {
          display: inline-block;
          background-color: $grey-color_shade1;
          border-radius: 5px;
          padding: 8px 40px 6px 12px;
          vertical-align: middle;
          font-size: 13px;
          position: relative;
          line-height: normal;
          margin-right: 8px;
          margin-bottom: 5px;
          &::before {
            vertical-align: middle;
            margin-right: 5px;
            width: 18px;
            height: 20px;
            display: inline-block;
          }
          &.doc-icon::before {
            content: url(../../../../static/icons/svg/wordIcon.svg);
          }
          &.docx-icon::before {
            content: url(../../../../static/icons/svg/wordIcon.svg);
          }
          &.pdf-icon::before {
            content: url(../../../../static/icons/svg/pdfIcon.svg);
          }
          &.jpg-icon::before {
            content: url(../../../../static/icons/svg/jpgIcon.svg);
          }
          &.png-icon::before {
            content: url(../../../../static/icons/svg/pngIcon.svg);
          }
          &.csv-icon::before {
            content: url(../../../../static/icons/svg/csvIcon.svg);
          }
          &.xlsx-icon::before {
            content: url(../../../../static/icons/svg/exceLicon.svg);
          }
          &.ppt-icon::before {
            content: url(../../../../static/icons/svg/powerpoint.svg);
          }
          &.pptx-icon::before {
            content: url(../../../../static/icons/svg/pptx.svg);
          }
          &.hyperlink-icon::before {
            content: url(../../../../static/icons/svg/hyperlink-icon.svg);
          }
        }
        .chipDelete_Btn {
          @include position(
            absolute,
            $top: 8px,
            $right: 10px,
            $bottom: null,
            $left: null
          );
          padding: 2px 2px;
          height: 18px;
          width: 18px;
          border-radius: 50px !important;
          &:hover {
            background-color: $grey-color_shade1;
          }
        }
      }
    }
    &--actionButton {
      text-align: right;
      margin-top: auto;
      padding: 15px 20px;
      .stroke-btn {
        @include stroke-btn;
      }
      .solid-btn {
        @include nj_btn;
        margin-left: 8px;
      }
    }
    .left-bar-toggle {
      @include position(
        absolute,
        $top: 0,
        $right: null,
        $bottom: null,
        $left: -21px
      );
      background: $light-grey;
      width: 20px;
      height: 20px;
      line-height: 20px;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      cursor: pointer;
      -webkit-transition: all 0.3s;
      -o-transition: all 0.3s;
      transition: all 0.3s;
      &:hover {
        box-shadow: rgba(9, 30, 66, 0.08) 0px 0px 0px 1px,
          rgba(9, 30, 66, 0.08) 0px 2px 4px 1px;
      }
    }
  }
}



.greyHead-table {
  th {
    background-color: $gery_shade;
    line-height: normal;
  }
  th:first-child,
  td:first-child {
    padding-left: 22px !important;
  }
  .editBtn,
  .deleteBtn {
    color: $secondrycolor;
  }
  .deleteBtn {
    margin-left: 8px;
  }
}

.iconDiv svg {
  display: inline-block;
}




.smallest-modal {
  .MuiPaper-root {
    width: 400px;
    max-width: 100%;
    .modal-title {
      padding: 10px 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid $border-color;
      margin-bottom: 15px;
      h3 {
        font-size: $medium-l-size;
        font-weight: 500;
      }
    }
    .modalBody {
      padding: 0 16px;
    }
  }
  .modalFooter {
    padding: 10px 15px;
    text-align: center;
    button {
      @include nj_btn;
      background: $secondrycolor !important;
      color: $white-color !important;
      padding: 9px 20px;
      margin-top: 0px;
      font-size: 13px !important;
      &:disabled {
        background-color: $secondrycolor !important;
        color: $white-color !important;
        border: none !important;
        opacity: 0.5 !important;
      }
    }
  }
}
